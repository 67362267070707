import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: 'waveform',
    component: () => import('./waveform.vue'),
  },
];

export default routes;

import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from 'vue-router';
import toolbox from './toolbox';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('./home.vue'),
  },
  {
    path: '/toolbox',
    component: RouterView,
    children: toolbox,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
